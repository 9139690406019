<!-- Controls the modal for creation of a new statement. Current modal dispolay is driven off of "step" variable. The process moves through three stages-- initial
statement creation where you pick a name and posted batches, adjustments, and finalization where the call to fire the statement dag is made. -->
<template>
  <v-dialog :value="value" persistent :width="checkWidth">
    <v-card tile v-if="step === 'Statement'" flat>
      <v-card-title class="ma-0 py-0 pl-4 pr-0">
        New Statement
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-col cols="3" class="pl-0">
          <v-subheader class="success--text">
            Statement Information
          </v-subheader>
        </v-col>
        <v-col class="pl-0">
          <v-icon>mdi-transfer-right</v-icon>
        </v-col>
        <v-col cols="2" class="pl-0">
          <v-subheader> Adjustments </v-subheader>
        </v-col>
        <v-col class="pl-0">
          <v-icon>mdi-transfer-right</v-icon>
        </v-col>
        <v-col cols="3" class="pl-0">
          <v-subheader> Generate Statement </v-subheader>
        </v-col>
      </v-card-title>
      <v-card-text class="pb-0">
        <v-form ref="form" lazy-validation>
          <v-container class="mx-0 px-0 mt-0">
            <v-row>
              <v-col>
                <v-text-field
                  v-model="statement.name"
                  label="Name*"
                  required
                  :rules="[(v) => !!v || 'Name is required', validateText]"
                  hide-details="auto"
                  outlined
                  dense
                  clearable
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-select
                  v-model="statement.responsible_party_id"
                  label="Responsible Party*"
                  :items="responsibleParties"
                  :menu-props="{ offsetY: true }"
                  item-text="name"
                  item-value="id"
                  :rules="[(v) => !!v || 'Responsible party is required']"
                  outlined
                  hide-details="auto"
                  dense
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn text depressed class="primary--text" @click="addNote">
                  <v-icon left>mdi-plus</v-icon>Add Note
                </v-btn>
                <v-textarea
                  v-if="noteStatus"
                  label="Optional Note"
                  v-model="statement.note"
                  :rules="[
                    (v) =>
                      (v || '').length <= 2000 ||
                      'Note must be 2000 characters or less',
                  ]"
                  outlined
                  hide-details="auto"
                  dense
                >
                </v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-row dense>
          <v-col cols="auto">
            <small>*Required field</small>
          </v-col>
          <v-col cols="auto" class="ml-auto">
            <v-btn
              text
              v-if="step === 'Statement'"
              color="grey darken-2"
              @click="$emit('update:value', false)"
            >
              Cancel
            </v-btn>
          </v-col>
          <v-col cols="auto">
            <v-btn
              color="success"
              @click="finishUp"
              :loading="loading"
              :disabled="!selected.length"
            >
              Continue
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
    <v-divider />
    <v-card tile flat v-if="step === 'Statement'">
      <v-toolbar flat color="fill" height="80px">
        <ToggleExpanded :refs="$refs"></ToggleExpanded>
        <v-divider vertical inset class="mx-4" />
        <v-col dense md="6" lg="6" class="ma-0 pa-0">
          <v-text-field
            @keydown.enter="searchBatches()"
            class="ma-0 pa-0"
            :placeholder="
              search.key
                ? `Search By ${search.key.description}`
                : 'Select Search Field'
            "
            outlined
            dense
            hide-details
            background-color="input"
            v-model="search.value"
          >
            <template #prepend-inner>
              <v-autocomplete
                style="margin-bottom: 8px; margin-top: 0; padding-bottom: 0"
                placeholder="Search By"
                append-icon=":"
                :items="searchParams"
                item-text="description"
                return-object
                hide-details
                solo
                flat
                single-line
                dense
                v-model="search.key"
              >
              </v-autocomplete>
            </template>
            <template #append>
              <v-tooltip top>
                <template #activator="{ on }">
                  <v-btn
                    color="primary"
                    v-on="on"
                    @click="
                      searched === false ? searchBatches() : clearSearch()
                    "
                    :loading="loading"
                  >
                    <v-icon v-if="!searched" class="white--text">
                      mdi-magnify
                    </v-icon>
                    <v-icon v-else-if="searched" class="white--text">
                      mdi-close
                    </v-icon>
                  </v-btn>
                </template>
                <span v-if="!searched">Search Batches</span>
                <span v-else-if="searched">Clear Search</span>
              </v-tooltip>
            </template>
          </v-text-field>
        </v-col>
      </v-toolbar>
      <v-data-table
        dense
        :items-per-page="pageSize"
        :headers="headers"
        group-by="post_id"
        height="280px"
        :items.sync="batches"
        :loading="loading"
        v-model="selected"
        show-select
        single-select
        :search="filter"
        item-key="post_id"
        fixed-header
        hide-default-footer
      >
        <template v-slot:[`item.responsible_party_id`]="{ item }">
          <span
            v-if="item && responsibleParties && responsibleParties.length > 0"
          >
            {{ getResponsiblePartyName(item.responsible_party_id) }}
          </span>
        </template>

        <template v-slot:[`group.header`]="{ group, headers, toggle, isOpen }">
          <td :colspan="headers.length">
            <v-row dense>
              <v-btn
                @click="toggle"
                small
                icon
                :ref="group"
                :data-open="isOpen"
              >
                <v-icon v-if="isOpen">mdi-chevron-up</v-icon>
                <v-icon v-else>mdi-chevron-down</v-icon>
              </v-btn>
              <v-col dense cols="9">
                Post Group: {{ group.substring(0, 8).toUpperCase() }}
              </v-col>
              <v-col justify="end" cols="2"></v-col>
            </v-row>
          </td>
        </template>

        <template v-slot:footer>
          <v-toolbar dense flat color="#fafafa">
            <v-row align="center" justify="end">
              <v-col cols="2" style="max-width: 150px">
                <v-select
                  class="mt-8"
                  dense
                  :items="pageSizes"
                  label="Items Per Page"
                  v-model="pageSize"
                  :menu-props="{ top: true, offsetY: true, maxHeight: 500 }"
                >
                </v-select>
              </v-col>
              <v-btn
                icon
                large
                @click="previous()"
                :disabled="disablePrevious"
                class="mx-2"
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <small>Page {{ page }}</small>
              <v-btn
                icon
                large
                @click="next()"
                :disabled="disableNext"
                class="mx-2"
              >
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </v-row>
          </v-toolbar>
        </template>
      </v-data-table>
    </v-card>
    <v-card v-if="step === 'Adjustments'" flat tile>
      <v-card-title class="ma-0 py-0 pl-4 pr-0">
        Optional Adjustments
        <v-divider inset class="mx-4" vertical></v-divider>
        <v-col cols="3" class="pl-0">
          <v-subheader> Statement Information </v-subheader>
        </v-col>
        <v-col class="pl-0">
          <v-icon>mdi-transfer-right</v-icon>
        </v-col>
        <v-col cols="2" class="pl-0">
          <v-subheader class="success--text"> Adjustments </v-subheader>
        </v-col>
        <v-col class="pl-0">
          <v-icon>mdi-transfer-right</v-icon>
        </v-col>
        <v-col cols="3" class="pl-0">
          <v-subheader> Generate Statement </v-subheader>
        </v-col>
      </v-card-title>
      <v-card-text class="pt-0">
        <v-form ref="form" lazy-validation>
          <v-container fluid class="mx-0 px-0 mt-0 pt-0">
            <v-subheader class="pl-0 pt-0 mt-0">
              Optional Party Selection
            </v-subheader>
            <v-row>
              <v-col cols="3">
                <v-select
                  v-model="adjustment.modality"
                  :items="modalities"
                  outlined
                  hide-details="auto"
                  dense
                  tabindex="0"
                  @change="checkAdjustmentParties"
                >
                </v-select>
              </v-col>
              <v-col cols="3">
                <v-select
                  label="Party Type"
                  v-model="adjustment.party_type"
                  :items="party_types"
                  item-text="kv_name"
                  item-value="kv_constant"
                  outlined
                  hide-details="auto"
                  dense
                  tabindex="0"
                  @change="checkAdjustmentParties"
                >
                </v-select>
              </v-col>
              <v-col cols="7" v-if="adjustment.modality !== 'ALL'">
                <v-autocomplete
                  v-model="adjustment.party_ids"
                  :label="activePartyOption.label"
                  multiple
                  outlined
                  :items="activePartyOption.items"
                  small-chips
                  :item-text="activePartyOption.text"
                  :item-value="activePartyOption.value"
                  hide-details="auto"
                  :loading="loadingParties"
                  dense
                  tabindex="0"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-subheader class="pl-0 pt-0 mt-4">
              Adjustment Selection
            </v-subheader>
            <v-row>
              <v-col cols="3">
                <v-select
                  v-model="adjustment.adjustment_type_id"
                  label="Adjustment Type*"
                  :items="validAdjustmentTypes"
                  :menu-props="{ offsetY: true }"
                  item-text="description"
                  item-value="id"
                  outlined
                  hide-details="auto"
                  dense
                  tabindex="0"
                >
                </v-select>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-model="adjustment.description"
                  label="Description*"
                  required
                  hide-details="auto"
                  outlined
                  dense
                  tabindex="0"
                >
                </v-text-field>
              </v-col>
              <v-col cols="2">
                <v-select
                  label="Mode*"
                  v-model="type"
                  :items="types"
                  outlined
                  hide-details="auto"
                  dense
                  tabindex="0"
                >
                </v-select>
              </v-col>
              <v-col cols="2">
                <v-text-field
                  label="Amount*"
                  v-model="adjustment.amount"
                  dense
                  single-line
                  outlined
                  background-color="input"
                  hide-details
                  tabindex="0"
                  messages="Click Icon for %"
                >
                  <template #prepend-inner>
                    <v-icon style="cursor: pointer" @click="switchAmount()">
                      {{ amountIcon }}
                    </v-icon>
                  </template>
                </v-text-field>
              </v-col>
              <v-col>
                <v-btn
                  text
                  depressed
                  class="primary--text"
                  @click="addNote"
                  tabindex="0"
                >
                  <v-icon left>mdi-plus</v-icon>Add Note
                </v-btn>
                <v-text-field
                  v-if="noteStatus"
                  label="Optional Note"
                  v-model="adjustment.note"
                  :rules="[
                    (v) =>
                      (v || '').length <= 256 ||
                      'Note must be 256 characters or less',
                  ]"
                  height="80px"
                  outlined
                  dense
                  tabindex="0"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-row>
          <v-col cols="auto">
            <v-btn
              color="accent"
              @click="pushAdjustment"
              :loading="loading"
              :disabled="loading"
              tabindex="1"
            >
              <v-icon>mdi-plus</v-icon>
              Add
            </v-btn>
          </v-col>
          <v-col cols="auto">
            <small>*Required field</small>
          </v-col>
          <v-col cols="auto" class="ml-auto">
            <v-btn
              text
              v-if="step === 'Adjustments'"
              color="grey darken-2"
              @click="cancelStatement"
            >
              Cancel
            </v-btn>
          </v-col>
          <v-col cols="auto">
            <v-btn
              color="success"
              @click="generate"
              :loading="generating"
              :disabled="generating"
            >
              Generate Statement
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
    <v-divider />
    <v-card color="#fafafa" v-if="step === 'Adjustments'">
      <v-data-table
        :items.sync="adjustments"
        dense
        :headers="headersAdj"
        height="200px"
        :loading="loading"
        v-ripple="false"
        class="table"
        fixed-header
        :search="filter"
      >
        <template #[`item.actionsAdj`]="{ item }">
          <v-btn icon @click.stop="deleteAdjustment(item)">
            <v-icon>mdi-trash-can</v-icon>
          </v-btn>
        </template>
        <template #[`item.adjustment_type`]="{ item }">
          <span>{{ formatAdjustmentType(item.adjustment_type_id) }}</span>
        </template>
        <template #[`item.mode`]="{ item }">
          <span v-if="item.amount < 0">DEBIT</span>
          <span v-else-if="item.amount > 0">CREDIT</span>
        </template>
        <template #[`item.amount`]="{ item }">
          <span v-if="!item.is_percent"
            >${{ formatCurrencyDisplay(item.amount) }}</span
          >
          <span v-else-if="item.is_percent">N/A</span>
        </template>
        <template #[`item.percent`]="{ item }">
          <span v-if="item.is_percent"
            >{{ formatCurrencyDisplay(item.amount) }}%</span
          >
          <span v-else-if="!item.is_percent">N/A</span>
        </template>
        <template #[`item.party`]="{ item }">
          <span>{{ item.modality }} {{ item.party_type }}</span>
        </template>
      </v-data-table>
    </v-card>
    <v-card color="#fafafa" v-if="step === 'Finish'">
      <section class="ma-0 pa-6">
        <v-row justify="center">
          <v-icon x-large color="success"
            >mdi-checkbox-marked-circle-outline</v-icon
          >
        </v-row>
        <v-row justify="center">
          <span class="pa-4"> Statement Generating! </span>
        </v-row>
        <v-row justify="center">
          <v-btn
            text
            color="grey darken-2"
            @click="$emit('closeModal', true)"
          >
            Return to Statements
          </v-btn>
        </v-row>
      </section>
    </v-card>
  </v-dialog>
</template>
<script>
// Components
import ToggleExpanded from "@/components/ToggleExpanded.vue";
// Mixins
import { fullWidth } from "@/mixins/fullWidth";
import { displayAlert } from "@/mixins/displayAlert";
import { format } from "@/mixins/format";
import { validation } from "@/mixins/validation";
import { userAccess } from "@/mixins/user-access";
import { utils } from "@/mixins/utils";
export default {
  data() {
    return {
      loading: false,
      generating: false,
      loadingParties: false,
      adding: false,
      adjustment: {
        description: "",
        adjustment_type_id: null,
        amount: 0.0,
        note: "",
        party_ids: [],
        modality: "ALL",
        party_type: "STORE",
        is_percent: false,
      },
      adjustments: [],
      batchPage: 1,
      modal: false,
      batches: [],
      expanded: [],
      filter: "",
      modality: "ALL",
      noteStatus: false,
      search: { value: null },
      searched: false,
      selected: [],
      statement: {
        responsible_party_id: null,
        description: null,
        post_ids: [],
        name: null,
        note: "",
        status: "PENDING",
        adjustment_id: [],
        amount: 0,
      },
      statement_id: "",
      step: "Statement",
      stores: [],
      responsible_party_id: null,
      parties: [],
      percentage: false,
      type: "",
      types: ["Debit", "Credit"],
      vendors: [],
      page: 1,
      pageSize: 50,
      pageSizes: [50, 100],
      selectedParty: {},
    };
  },
  name: "StatementModal",
  components: { ToggleExpanded },
  props: ["value"],
  mixins: [displayAlert, fullWidth, format, userAccess, utils, validation],
  watch: {
    selected: {
      handler(values) {
        if(values[0]){
          this.statement.name = values[0].name;
        }
      },
    },
    modal: {
      handler(value) {
        if (!value) {
          this.selectedStatement = {};
        }
      },
    },
  },
  async created() {
    if (this.responsibleParties.length >= 1) {
      this.statement.responsible_party_id = this.responsibleParties[0].id;
      await this.getBatches();
    }
  },
  computed: {
    responsibleParties() {
      return this.$store.getters.responsibleParties;
    },
    party_types() {
      return this.$store.getters.partyTypes.filter(
        (type) => type?.kv_constant !== "ADGROUP"
      );
    },
    adjustmentTypes() {
      return this.$store.getters.adjustmentTypes;
    },
    validAdjustmentTypes() {
      const partyType = this.party_types.find(
        (type) => type?.kv_constant === this.adjustment.party_type
      );
      return this.adjustmentTypes.filter((a) => {
        return !a.party_type_id || a.party_type_id === partyType?.kv_id;
      });
    },
    activePartyOption() {
      if (this.adjustment.party_type) {
        return this.adjustmentPartyOptions[this.adjustment.party_type];
      }
      return { text: "", value: "", items: [] };
    },
    adjustmentPartyOptions() {
      return {
        STORE: {
          items: this.stores,
          text: "party_name",
          value: "party_id",
          label: "Stores",
        },
        VENDOR: {
          items: this.vendors,
          text: "vendor_name",
          value: "vendor_id",
          label: "Vendors",
        },
      };
    },
    offset() {
      return (this.page - 1) * this.pageSize;
    },
    disablePrevious() {
      return this.offset === 0;
    },
    disableNext() {
      return this.batches.length < this.pageSize;
    },
    headers() {
      return [
        { sortable: false },
        { text: "Batch Name", value: "name" },
        { text: "Batch Type", value: "batch_type" },
        { text: "Start Date", value: "start_date" },
        { text: "End Date", value: "end_date" },
        { text: "Post Date", value: "post_date" },
        { text: "Post ID", value: "post_id" },
      ];
    },
    headersAdj() {
      return [
        { text: "Note", value: "description" },
        { text: "Adjustment Type", value: "adjustment_type" },
        { text: "Mode", value: "mode" },
        { text: "Cash Amount", value: "amount" },
        { text: "Percentage", value: "percent" },
        { text: "Party Type", value: "party" },
        { text: "Actions", value: "actionsAdj" },
      ];
    },
    checkWidth() {
      return this.step == "Finish" ? "300px" : "1000px";
    },
    modalities() {
      return [
        { text: "All", value: "ALL" },
        { text: "Include Selected", value: "INCLUDE" },
        { text: "Exclude Selected", value: "EXCLUDE" },
      ];
    },
    amountIcon() {
      return this.percentage === true
        ? "mdi-percent-outline"
        : "mdi-currency-usd";
    },
    searchParams() {
      return [
        { description: "Item ID", param: "item_id" },
        { description: "UPC", param: "upc" },
        { description: "Store Number", param: "store_number" },
        { description: "Promo Name", param: "promo_name" },
        { description: "Ad Group", param: "ad_group" },
      ];
    },
  },
  methods: {
    addNote() {
      this.noteStatus = true;
    },
    async getBatches() {
      this.loading = true;
      const params = {
        limit: this.pageSize,
        offset: this.offset,
        responsible_party_id: this.statement.responsible_party_id,
      };
      if (this.limitAccessByUserParties) {
        params.party_ids = [...this.userBatchPartyIds];
      }
      try {
        const res = await this.$BillingBatch.getPostedBatchesSearch(params);
        this.batches = res?.data || [];
      } catch (err) {
        this.handleError(err);
      } finally {
        this.loading = false;
      }
    },
    async searchBatches() {
      this.loading = true;
      if (!this.searched) {
        this.batchPage = this.page;
        this.page = 1;
      }
      let key = this.search.key?.param;
      let value = this.search.value;
      const params = {
        limit: this.pageSize,
        offset: this.offset,
      };
      if (key && value) {
        params[`${key}`] = value;
      }
      if (this.limitAccessByUserParties) {
        params.party_ids = [...this.userBatchPartyIds];
      }
      try {
        const res = await this.$BillingBatch.getPostedBatchesSearch(params);
        this.batches = res?.data || [];
      } catch (err) {
        this.handleError(err);
      } finally {
        this.loading = false;
        this.searched = true;
      }
    },
    clearSearch() {
      this.search = { value: null };
      this.page = this.batchPage;
      this.search.value = null;
      this.searched = false;
      this.getBatches();
    },
    deleteAdjustment(item) {
      this.adjustments = this.adjustments.filter(
        (adjustment) => adjustment != item
      );
    },
    validate() {
      return this.$refs.form.validate();
    },
    getResponsiblePartyName(id) {
      if (this.responsibleParties.length > 0) {
        const party = this.responsibleParties.find((p) => p.id === id);
        if (party) {
          return party.name;
        }
      }
      return "";
    },
    close() {
      this.$emit("close");
    },
    clearAdjustment() {
      this.adjustment.description = "";
      this.adjustment.note = "";
      this.adjustment.amount = 0.0;
      this.adjustment.adjustment_type_id = null;
      this.adjustment.party_ids = [];
      this.adjustment.modality = "ALL";
      this.adjustment.party_type = "STORE";
      this.adjustment.is_percent = false;
      this.noteStatus = false;
    },
    pushAdjustment() {
      const target = this._.clone(this.adjustment);
      if (this.type == "Debit") {
        target.amount = target.amount * -1;
      }
      target.is_percent = this.percentage;
      // target.party_type = this.selectedParty.kv_constant
      this.adjustments.push(target);
      this.clearAdjustment();
    },
    formatAdjustmentType(adjustment_type_id) {
      const type = this.adjustmentTypes.find(
        (a) => a.id === adjustment_type_id
      );
      if (type) {
        return type.name;
      }
    },
    async cancelStatement() {
      try {
        await this.$Statements.deleteStatement(this.statement_id);
        this.$emit("update:value", false);
      } catch (err) {
        this.handleError(err);
      }
    },
    async finishUp() {
      this.statement.post_ids = this.selected.map((batch) => batch.post_id);
      this.loading = true;
      try {
        const res = await this.$Statements.postStatement(this.statement);
        this.statement_id = res?.data?.id;
        if (this.statement_id) {
          this.step = "Adjustments";
        }
      } catch (err) {
        this.handleError(err);
      } finally {
        this.loading = false;
      }
    },
    async generate() {
      this.generating = true;
      this.$store.dispatch("setSnackbar", {
        status: "success",
        text: `Generating Statement, Please Wait...`,
      });
      const body = {
        statement_id: this.statement_id,
        adjustments: this.adjustments,
      };
      try {
        const res = await this.$Statements.generate(body);
        if (res?.status === 200) {
          this.step = "Finish";
        }
      } catch (err) {
        this.handleError(err);
      } finally {
        this.generating = false;
      }
    },
    checkAdjustmentParties() {
      if (this.adjustment.modality !== "ALL") {
        const partyType = this.adjustment.party_type;
        if (partyType === "STORE" && this.stores.length === 0) {
          this.getStores();
        } else if (partyType === "VENDOR" && this.vendors.length === 0) {
          this.getVendors();
        }
      }
    },
    async getStores() {
      this.loadingParties = true;
      try {
        const res = await this.$BillingBatch.storeList(this.statement_id);
        this.stores = res?.data || [];
      } catch (err) {
        this.handleError(err);
      } finally {
        this.loadingParties = false;
      }
    },
    async getVendors() {
      this.loadingParties = true;
      try {
        const res = await await this.$BillingBatch.vendorList(
          this.statement_id
        );
        this.vendors = res?.data || [];
      } catch (err) {
        this.handleError(err);
      } finally {
        this.loadingParties = false;
      }
    },
    updateResponsibleParty() {
      this.responsible_party_id = this.statement.responsible_party_id;
    },
    switchAmount() {
      this.percentage = !this.percentage;
    },
    previous() {
      this.page = this.page - 1;
      if (this.page < 1) {
        this.page = 1;
      }
      if (this.searched) {
        this.searchBatches();
      } else {
        this.getBatches();
      }
    },
    next() {
      this.page = this.page + 1;
      if (this.searched) {
        this.searchBatches();
      } else {
        this.getBatches();
      }
    },
  },
};
</script>
