export const validation = {
    methods: {
      validateText (value) {
        const allowedChars = ['/', '-', ' ', '.']
        if (value) {
          const chars = [...value]
          const invalidChars = chars.filter(c => {
            return (/[\W_]+/g.test(c)) && !allowedChars.includes(c)
          })
          return (invalidChars.length === 0) || `Invalid characters: ${[...new Set(invalidChars)].join(' ')}`
        }
        return true
      }
    }
}