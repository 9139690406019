<template>
  <v-btn-toggle dense mandatory>
    <v-tooltip top>
      <template #activator="{ on }">
        <v-btn
          v-on="on"
          @click="closeAll">
          <v-icon color="main">mdi-collapse-all-outline</v-icon>
        </v-btn>
      </template>
      <span>Collapse Rows</span>
    </v-tooltip>
    <v-tooltip top>
      <template #activator="{ on }">
        <v-btn
          v-on="on"
          @click="openAll">
          <v-icon color="main">mdi-expand-all-outline</v-icon>
        </v-btn>
      </template>
      <span>Expand Rows</span>
    </v-tooltip>
  </v-btn-toggle>
</template>
<script>
export default {
  name: 'ToggleExpanded',
  props: {
    refs: Object
  },
  methods: {
    closeAll () {
      Object.keys(this.refs).forEach(k => {
        if (this.refs[k] && this.refs[k].$attrs['data-open']) {
          this.refs[k].$el.click()
        }
      })
    },
    openAll () {
      Object.keys(this.refs).forEach(k => {
        if (this.refs[k] && !this.refs[k].$attrs['data-open']) {
          this.refs[k].$el.click()
        }
      })
    }
  }
}
</script>